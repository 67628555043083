import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'app/public-path';
import 'app/bootstrap';

const entrypoint = window.__CONFIG__.js_entrypoint;
const page = window.__CONFIG__.js_page;

function loadJS() {
  if (page) {
    /*
    Webpack is capable of dynamically loading modules,
    however it needs to know the start point of where to look for modules to load.
    This is done at compile time, so here we specify beginning of the path to the import
    and supply the rest of the path dynamically.

    This does look odd, replacing the string,
    and then inserting it manually back into the import,
    but this is the only way to give webpack a hint of how to bundle the modules
    */

    let importedModule;
    if (page.includes('views/casting_portal/')) {
      const path = page.replace('views/casting_portal/', '');
      importedModule = import(`views/casting_portal/${path}`);
    } else if (page.includes('views/shared_portals/')) {
      const path = page.replace('views/shared_portals/', '');
      importedModule = import(`views/shared_portals/${path}`);
    } else if (page.includes('views/crew_portal/')) {
      const path = page.replace('views/crew_portal/', '');
      importedModule = import(`views/crew_portal/${path}`);
    }

    if (__DEV__ && importedModule) {
      importedModule.catch((error) => {
        // eslint-disable-next-line no-console
        console.error(
          `%c [POPSSS]

Unable to load module: ${page}
${error}
`,
          'font-weight: bold',
          'font-weight: bold',
        );
      });
    }
  }

  switch (entrypoint) {
    case 'activity':
      return import('views/activity');
    case 'admin':
      return import('views/admin');
    case 'approval_queue':
      return import('views/approval_queue');
    case 'auth':
      return import('views/auth');
    case 'login':
      return import('views/login');
    case 'backoffice':
      return import('views/backoffice');
    case 'contracts':
      return import('views/contracts');
    case 'crud':
      return import('views/crud');
    case 'dashboard':
      return import('views/dashboard');
    case 'double_booked':
      return import('views/double_booked');
    case 'download':
      return import('views/download');
    case 'list':
      return import('views/list');
    case 'list_picker':
      return import('views/list_picker');
    case 'listbrowser':
      return import('views/listbrowser');
    case 'lookbook':
      return import('views/lookbook');
    case 'production_listbrowser':
      return import('views/production_listbrowser');
    case 'message_compose':
      return import('views/message_compose');
    case 'message_inbox':
      return import('views/message_inbox');
    case 'message_overview':
      return import('views/message_overview');
    case 'message_usage':
      return import('views/message_usage');
    case 'message_view':
      return import('views/message_view');
    case 'onset':
      return import('views/onset_beta');
    case 'onset_beta':
      return import('views/onset_beta');
    case 'payments':
      return import('views/payments');
    case 'casting_dashboard':
      return import('views/casting_dashboard');
    case 'production_briefing':
      return import('views/production_briefing');
    case 'production_onset':
      return import('views/production_onset');
    case 'profile':
      return import('views/profile');
    case 'project':
      return import('views/project');
    case 'project_record':
      return import('views/project_record');
    case 'project_summaries':
      return import('views/project_summaries');
    case 'quicksearch':
      return import('views/quicksearch');
    case 'register':
      return import('views/register');
    case 'search_beta':
      return import('views/search_beta');
    case 'site_form':
      return import('views/site_form');
    case 'staff':
      return import('views/staff');
    case 'summary':
      return import('views/summary');
    case 'training':
      return import('views/training');
  }
}

if (entrypoint || page) {
  loadJS()?.catch((error) => {
    // eslint-disable-next-line no-console
    console.log(error);

    // Show an alert, so the User can reload the page
    const content = document.getElementById('content-body');
    const wrapper = document.createElement('div');
    wrapper?.classList.add(...['text-center', 'alert', 'alert-danger']);

    const heading = document.createElement('h1');
    heading.innerText = 'Something went wrong.';

    const subHeading = document.createElement('h3');
    subHeading.innerText =
      'Please try reloading your page and clearing your cache.';

    const paragraphElement = document.createElement('p');
    paragraphElement.innerHTML =
      'If you are still experiencing difficulties please try a different ' +
      'internet browser, we recommend using the most up to date version of ' +
      '<a href="https://www.google.com/chrome/">' +
      '<strong>Google Chrome</strong></a>' +
      ' to have the best experience on POP.';

    wrapper?.appendChild(heading);
    wrapper?.appendChild(subHeading);
    wrapper?.appendChild(paragraphElement);

    content?.appendChild(wrapper);
  });
}
