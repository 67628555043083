import React from 'react';
import { createRoot } from 'react-dom/client';
import classNames from 'classnames';
import styles from './GDPRBanner.css';
import PropTypes from 'prop-types';

import { hasLocalStorage } from 'lib/local-storage-check';

const GDPR_WARNING_DISMISS = 'gdpr-warning-dismiss';

function shouldShowGDPRWarning() {
  return hasLocalStorage() && !localStorage.getItem(GDPR_WARNING_DISMISS);
}

function GDPRBanner({ date }) {
  function dismiss() {
    $('#browser-warning-gdpr').fadeOut();
    if (hasLocalStorage()) {
      localStorage.setItem(GDPR_WARNING_DISMISS, 'dismissed');
    }
  }

  return (
    <div
      id="browser-warning-gdpr"
      className={classNames(
        'alert alert-warning gdpr-warning z4',
        styles.banner,
      )}
    >
      <span className={styles.text}>
        From {date}, you will need to reset your password and set up MFA to log
        in. Learn more at{' '}
        <a href="https://www.ep.com/mfa/casting-portal/">
          ep.com/mfa/casting-portal/
        </a>
      </span>
      <button
        className={classNames('close', styles.close, 'p1')}
        id="gdpr-browser-warning-close-button"
        onClick={dismiss}
      >
        x
      </button>
    </div>
  );
}

export function renderGDPRWarningBanner() {
  $(document).ready(() => {
    if (shouldShowGDPRWarning()) {
      const GDPRContainer = document.getElementById('gdpr-warning');
      const date = GDPRContainer?.dataset.info;
      if (GDPRContainer && date) {
        const root = createRoot(GDPRContainer);
        root.render(<GDPRBanner date={date} />);
      }
    }
  });
}

GDPRBanner.propTypes = {
  date: PropTypes.string,
};
